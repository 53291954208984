import Vue from "vue";
import CryptoJS from "crypto-js";

export class Auxiliares {
  formatearFecha(fechaVenta: Date): string {
    const fecha: Date = new Date(fechaVenta);
    const months: Array<string> = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ];
    const fechaString = (date: Date) => {
      return (
        date.getDate() +
        "-" +
        months[date.getMonth()] +
        "-" +
        date.getFullYear() +
        " " +
        date.toLocaleTimeString()
      );
    };
    return fechaString(fecha);
  }

  encriptar(texto: string): string {
    return CryptoJS.AES.encrypt(
      texto,
      "fsha32tjrd42f3j466rtsr366nfghdf"
    ).toString();
  }
  desencriptar(texto: string): string {
    return CryptoJS.AES.decrypt(
      texto,
      "fsha32tjrd42f3j466rtsr366nfghdf"
    ).toString(CryptoJS.enc.Utf8);
  }

  recuperarToken(): string {
    return this.desencriptar(localStorage.getItem("token"));
  }
  guardarToken(texto: string): void {
    localStorage.setItem("token", this.encriptar(texto));
  }
}
