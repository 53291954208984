import { Respuesta } from "./../Models/IRespuestas";
import { Login, LoginResponse } from "@/Models/ILogin";

export class wsSync {
  base_url = "https://syncsistemaventastest.azurewebsites.net/";
  constructor() {
    if (process.env.NODE_ENV === "production") {
      this.base_url = "https://syncsistemaventas.azurewebsites.net/";
    }
  }

  async cambiarContrasena<T>(
    token: string,
    usuario: Login
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(this.base_url + "Usuario", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(usuario),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }

  async consUsuario<T>(token: string): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(this.base_url + "Usuario", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consProductos<T>(token: string): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(this.base_url + "Producto", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consProductosPorNegocio<T>(
    token: string,
    idNegocio: number,
    cantidad: number,
    pagina: number,
    ordenarpor: string,
    textoBuscar: string,
    descendente: boolean
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const params = new URLSearchParams({
      idNegocio: idNegocio.toString(),
      cantidad: cantidad.toString(),
      pagina: pagina.toString(),
      ordenarpor: ordenarpor,
      textoBuscar: textoBuscar,
      descendente: String(descendente),
    });
    const res = await fetch(this.base_url + "Producto/negocio?" + params, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consVentas<T>(token: string): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(this.base_url + "Venta", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consVentasPorNegocio<T>(
    token: string,
    idNegocio: number,
    cantidad: number,
    pagina: number,
    ordenarpor: string,
    textoBuscar: string,
    descendente: boolean
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const params = new URLSearchParams({
      idNegocio: idNegocio.toString(),
      cantidad: cantidad.toString(),
      pagina: pagina.toString(),
      ordenarpor: ordenarpor,
      textoBuscar: textoBuscar,
      descendente: String(descendente),
    });
    const res = await fetch(this.base_url + "Venta/negocio?" + params, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consVentasPorNegocioAnual<T>(
    token: string,
    idNegocio: number
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const params = new URLSearchParams({ idNegocio: idNegocio.toString() });
    const res = await fetch(this.base_url + "Venta/negocio/anual?" + params, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async RegistrarMovimientoInventario<T>(
    token: string,
    idproducto: string,
    negocio: string,
    movimiento: number,
    cantidad: number,
    idNegocio: number,
    lote:string,
    fecha:Date
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const params = new URLSearchParams({
      idproducto:idproducto,
      negocio:negocio,
      movimiento:movimiento.toString(),
      cantidad: cantidad.toString(),
      idNegocio: idNegocio.toString(),
      lote:lote,
      fecha:fecha.toString()
    });
    const res = await fetch(this.base_url + "Producto/inventario?" + params, {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consNegocios<T>(token: string): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(this.base_url + "Negocio", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async consTicket<T>(
    token: string,
    idTicket: number,
    idNegocio: number
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const res = await fetch(this.base_url + "Ticket", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ idTicket: idTicket, idNegocio: idNegocio }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
  async iniciarSesion<T>(usuario: Login): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const response: Respuesta<T> = await fetch(
      this.base_url + "Usuario/login",
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(usuario),
        redirect: "follow",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });

    return response;
  }
  async consCaducidades<T>(
    token: string,
    idNegocio: number,
    id: string,
  ): Promise<Respuesta<T>> {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    myHeaders.append("Content-Type", "application/json");
    const params = new URLSearchParams({
      idNegocio: idNegocio.toString(),
      id: id,
    });
    const res = await fetch(this.base_url + "caducidades/producto?" + params, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const respuesta: Respuesta<T> = {
          exito: 200,
          mensaje: data.mensaje,
          data: data.data,
        };
        return respuesta;
      })
      .catch((error) => {
        const respuesta: Respuesta<T> = {
          exito: error.message === "Unauthorized" ? 401 : 500,
          mensaje: error.message,
          data: new Object() as T,
        };
        return respuesta;
      });
    return res;
  }
}
