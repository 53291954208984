import { Respuesta } from "./../Models/IRespuestas";
import { VentaModel } from "./../Models/IVenta";
import { wsSync } from "@/services/wsSync";
import Vue from "vue";
import Vuex from "vuex";
import { ProductoModel } from "@/Models/IProducto";
import { NegocioModel } from "@/Models/INegocio";
import { ResumenVenta } from "@/Models/IResumenVenta";
import { ResumenProducto } from "@/Models/IResumenProducto";
import { Notificacion } from "@/Models/INotificaciones";
import { Auxiliares } from "@/services/Auxiliares";
import * as signalR from "@microsoft/signalr";
import Toasted from "vue-toasted";
import push from 'push.js';

let base_url =
  "https://syncsistemaventastest.azurewebsites.net/notificacioneshub" as string;
if (process.env.NODE_ENV === "production") {
  base_url = "https://syncsistemaventas.azurewebsites.net/notificacioneshub";
}
const connection = new signalR.HubConnectionBuilder().withUrl(base_url).build();

Vue.use(Vuex);
Vue.use(Toasted);
const aux = new Auxiliares();
export default new Vuex.Store({
  state: {
    Productos: null as Array<ProductoModel>,
    Ventas: null as Array<VentaModel>,
    Negocios: [] as Array<NegocioModel>,
    ResumenProductos: null as Array<ResumenProducto>,
    ResumenVentas: null as Array<ResumenVenta>,
    Notificaciones: [] as Array<Notificacion>,
    ConexionNotificaciones: false as boolean,
    snackbar: {
      visible: false,
      color: "success",
      text: null,
      timeout: 10000,
      multiline: false
    }
  },
  mutations: {
    alert(state, payload) {
      console.log(payload)
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text.length;

      // Si es mas de 50 caracteres definirlo como multilinea
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline;
      }
    
      // Color para mensajes de error o éxito
      if (payload.color) {
        state.snackbar.color = payload.color;
      }

     // Tiempo de duración
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout;
      }
      state.snackbar.visible = true;
   },
   closeAlert(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.text = null;
    },
    setConexion(state, payload) {
      state.ConexionNotificaciones = payload;
    },
    setNotificaciones(state, payload) {
      state.Notificaciones.unshift(payload);
    },
    /**
     * Recibe lista actualizada de notificaciones
     * @param state no es necesario enviar
     * @param payload indice
     */
    borrarNotificacion(state, payload) {
      state.Notificaciones.splice(payload, 1);
    },
    setProductos(state, payload) {
      state.Productos = payload;
    },
    setVentas(state, payload) {
      state.Ventas = payload;
    },
    setResumenProductos(state, payload) {
      state.ResumenProductos = payload;
    },
    setResumenVentas(state, payload) {
      state.ResumenVentas = payload;
    },
    setNegocios(state, payload) {
      state.Negocios = payload;
    },
  },
  actions: {
    async getNegocios({ commit }): Promise<Respuesta<Array<NegocioModel>>> {
      const repo = new wsSync();
      const token: string = aux.recuperarToken();
      const res: Respuesta<Array<NegocioModel>> = await repo
        .consNegocios(token)
        .then((response: Respuesta<Array<NegocioModel>>) => {
          return response;
        })
        .catch((error: Respuesta<Array<NegocioModel>>) => {
          return error;
        });
      if (res.exito == 200) {
        localStorage.setItem(
          "Negocios",
          aux.encriptar(JSON.stringify(res.data))
        );
        commit("setNegocios", res.data);
      }
      return res;
    },
    async getProductosPorNegocio(): Promise<Respuesta<Array<ResumenProducto>>> {
      const repo = new wsSync();
      const token: string = aux.recuperarToken();
      const res: Respuesta<Array<ResumenProducto>> = await repo
        .consProductos(token)
        .then((response: Respuesta<Array<ResumenProducto>>) => {
          return response;
        })
        .catch((error: Respuesta<Array<ResumenProducto>>) => {
          return error;
        });
      return res;
    },
    async getVentasPorNegocio(): Promise<Respuesta<Array<ResumenVenta>>> {
      const repo = new wsSync();
      const token: string = aux.recuperarToken();
      const res: Respuesta<Array<ResumenVenta>> = await repo.consVentas(token);

      return res;
    },
    async getResumenProductos({
      commit,
    }): Promise<Respuesta<Array<ResumenProducto>>> {
      const repo = new wsSync();
      const token: string = aux.recuperarToken();
      const res: Respuesta<Array<ResumenProducto>> = await repo
        .consProductos(token)
        .then((response: Respuesta<Array<ResumenProducto>>) => {
          return response;
        })
        .catch((error: Respuesta<Array<ResumenProducto>>) => {
          return error;
        });
      if (res.exito == 200) {
        commit("setResumenProductos", res.data);
      }
      return res;
    },

    async getResumenVentas({
      commit,
    }): Promise<Respuesta<Array<ResumenVenta>>> {
      const repo = new wsSync();
      const token: string = aux.recuperarToken();
      const res: Respuesta<Array<ResumenVenta>> = await repo.consVentas(token);
      if (res.exito == 200) {
        commit("setResumenVentas", res.data);
      }
      return res;
    },

    async conectarseNotificaciones({ commit }, payload) {
      await connection
        .start()
        .then(function () {
          console.info("Se conectó correctamente a las notificaciones");
        })
        .catch(function (error) {
          console.error("No se pudo conectar a las notificaciones " + error);
        });

      if (!payload.conexion) {
        for (const negocio of payload.negocios) {
          await connection.invoke("Agregar_A_Grupo", negocio.nombre);
        }

        Notification.requestPermission().then(function(permission) {
          if (permission === 'granted') {
            console.log('Permission granted');
          }
        });

        //  connection.on(
        //   "SolicitudAutorizacion",
        //   (negocio: string, user: string, detalleAutorizacion: string) => {
        //     push.create(negocio,{
        //       body: detalleAutorizacion,
        //     });
        //     const notificacion = {} as Notificacion;
        //     notificacion.acciones = true;
        //     notificacion.descripcion = detalleAutorizacion;
        //     notificacion.fecha = new Date();
        //     notificacion.solicitante = user;
        //     notificacion.negocio = negocio;
        //     notificacion.titulo = "Solicitud de Autorización";
        //     commit("setNotificaciones", notificacion);
        //     commit("setConexion", true);
            
        //     // notification
        //     // window.Notification;
        //     // alert(detalleAutorizacion);
        //   }
        // );
      }
    },
    registrarNotificacion({commit},notificacion){
      commit("setNotificaciones", notificacion);
    },
    setConexion({commit}){
      commit("setConexion", true);
    },
    async enviarAutorizacion({ commit }, payload) {
      await connection.invoke(
        "RecibirAutorizacion",
        payload.negocio,
        payload.respuesta
      );
      commit("borrarNotificacion", payload.indice);
    },
  },
  modules: {},
});
