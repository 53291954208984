import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Inicio",
    component: Home,
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/login",
    name: "Iniciar sesión",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      redirectLogin: true,
    },
  },
  {
    path: "/productos",
    name: "Productos",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Productos.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/ventas",
    name: "Ventas",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Ventas.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Perfil.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/notificaciones",
    name: "Notificaciones",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Notificaciones.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
];

const router: any = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: any, from: any, next: any) => {
  const roles = localStorage.getItem("roles");
  if (
    to.matched.some(
      (record: { meta: { requiresAdmin: any } }) => record.meta.requiresAdmin
    )
  ) {
    if (roles != null && roles == "admin") {
      next();
    } else {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    }
  } else if (
    to.matched.some(
      (record: { meta: { requiresUser: any } }) => record.meta.requiresUser
    )
  ) {
    if ((roles != null && roles == "user") || roles == "admin") {
      next();
    } else {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
  if (
    to.matched.some(
      (record: { meta: { redirectLogin: any } }) => record.meta.redirectLogin
    )
  ) {
    if (roles != null && roles == "admin") {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    }
  }
});

export default router;
