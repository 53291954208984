







































































































































































































import { NegocioModel } from "@/Models/INegocio";
import { Respuesta } from "@/Models/IRespuestas";
import { ResumenProducto } from "@/Models/IResumenProducto";
import { ResumenVenta } from "@/Models/IResumenVenta";
import { IVentaAnual } from "@/Models/IVentaAnual";
import { Auxiliares } from "@/services/Auxiliares";
import { wsSync } from "@/services/wsSync";
import Vue from "vue";
import * as signalR from "@microsoft/signalr";
import push from "push.js";
import { mapState, mapActions } from "vuex";
import { Notificacion } from "@/Models/INotificaciones";

const aux = new Auxiliares();
const repo = new wsSync();
let base_url =
  "https://syncsistemaventastest.azurewebsites.net/notificacioneshub" as string;
if (process.env.NODE_ENV === "production") {
  base_url = "https://syncsistemaventas.azurewebsites.net/notificacioneshub";
}
const connection = new signalR.HubConnectionBuilder().withUrl(base_url).build();
export default Vue.extend({
  components: {},
  data: () => ({
    ventasDelDia: 0 as number,
    ventasEfectivo: 0 as number,
    ventasTarjeta: 0 as number,
    negocios: [
      {
        idNegocio: 0,
        nombre: "",
      },
    ] as Array<NegocioModel>,
    resumenVentas: [
      {
        total: 0,
        efectivo: 0,
        tarjeta: 0,
        transferencia: 0,
        idNegocio: 0,
      },
    ] as Array<ResumenVenta>,
    resumenProductos: [
      {
        idNegocio: 0,
        productos: 0,
      },
    ] as Array<ResumenProducto>,
    ventasAnuales: {
      actual: [],
      pasado: [],
    } as IVentaAnual,
    indexNegocios: 0 as number,
    idNegocioSelect: 0 as number,
    alert: false as boolean,
    alertmsg: "" as string,
    alerttipo: "error" as string,
    cargadeDatos: false,
    mVntEfectivo: false as boolean,
    mVntTarjeta: false as boolean,
    attrs: {
      class: "mx-auto",
      boilerplate: false,
      elevation: 5,
    },
  }),
  methods: {
    ...mapActions([
      "getResumenProductos",
      "getResumenVentas",
      "getNegocios",
      "conectarseNotificaciones",
      "registrarNotificacion",
      "setConexion",
    ]),
    irProductos() {
      this.$router.push("Productos");
    },
    irVentas() {
      this.$router.push("Ventas");
    },

    ventasEfectivoPorNegocio(idNegocio: number) {
      let vntEfec = 0;
      this.Ventas.map((elem) => {
        if (elem.idNegocio == idNegocio) {
          vntEfec += elem.efectivo;
        }
      });
      return vntEfec;
    },
    ventasTarjetaPorNegocio(idNegocio: number) {
      let vntTarj = 0;
      this.Ventas.map((elem) => {
        if (elem.idNegocio == idNegocio) {
          vntTarj += elem.tarjeta;
        }
      });
      return vntTarj;
    },
    async consultarVentasAnuales() {
      const res = await repo
        .consVentasPorNegocioAnual<Respuesta<IVentaAnual>>(
          aux.recuperarToken(),
          this.negocios[this.indexNegocios == null ? 0 : this.indexNegocios]
            .idNegocio
        )
        .then((res) => res);
      if (res.exito != 200) {
        if (res.exito == 401) {
          this.alerttipo = "error";
          this.alertmsg =
            "Tu sesión caducó refresca la página o vuelve a iniciar sesión";
          this.alert = true;
          localStorage.clear();
        } else {
          this.alerttipo = "warning";
          this.alertmsg = "Ocurrió un error vuelve a intentar más tarde";
          this.alert = true;
        }
      }
      this.ventasAnuales = res.data;
    },
  },
  computed: {
    ...mapState([
      "ResumenProductos",
      "ResumenVentas",
      "Negocios",
      "ConexionNotificaciones",
    ]),
    actuales() {
      let ventas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] as Array<number>;
      this.ventasAnuales.actual.forEach((element) => {
        ventas[element.mes - 1] = element.total;
      });

      return ventas;
    },
    pasadas() {
      let ventas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] as Array<number>;
      this.ventasAnuales.pasado.forEach((element) => {
        ventas[element.mes - 1] = element.total;
      });

      return ventas;
    },
    productosPorNegocio() {
      let idNegocio =
        this.negocios[this.indexNegocios == null ? 0 : this.indexNegocios]
          .idNegocio;
      return this.resumenProductos.find(
        (elem) => elem.idNegocio == idNegocio
      ) == null
        ? this.resumenProductos[0]
        : this.resumenProductos.find((elem) => elem.idNegocio == idNegocio);
    },
    ventasPorNegocio() {
      let idNegocio =
        this.negocios[this.indexNegocios == null ? 0 : this.indexNegocios]
          .idNegocio;
      return this.resumenVentas.find((elem) => elem.idNegocio == idNegocio) ==
        null
        ? this.resumenVentas[0]
        : this.resumenVentas.find((elem) => elem.idNegocio == idNegocio);
    },
  },
  async mounted() {
    this.cargadeDatos = true;

    this.negocios = JSON.parse(
      aux.desencriptar(localStorage.getItem("Negocios"))
    );

    await this.consultarVentasAnuales();

    if (this.ResumenVentas == null) {
      let resPro = await this.getResumenProductos().then(
        (response) => response
      );
      let resVent = await this.getResumenVentas().then((response) => response);
      if (resPro.exito == 401 || resVent.exito == 401) {
        this.alerttipo = "red accent-2";
        this.alertmsg = "Tu sesión caducó vuelve a iniciar sesión";
        this.alert = true;
        localStorage.clear();
        this.$router.push("/");
      }
    }

    this.resumenVentas = this.ResumenVentas;
    this.resumenProductos = this.ResumenProductos;
    Notification.requestPermission();
    if (!push.Permission.GRANTED) {
      push.Permission.request();
    }

    await connection
      .start()
      .then(function () {
        console.info("Se conectó correctamente a las notificaciones");
      })
      .catch(function (error) {
        console.error("No se pudo conectar a las notificaciones " + error);
      });
    if (!this.ConexionNotificaciones) {
      console.log("No conectado");
      for (const negocio of this.negocios) {
        await connection.invoke("Agregar_A_Grupo", negocio.nombre);
      }

      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          console.log("Permission granted");
        }
      });

      connection.on(
        "SolicitudAutorizacion",
        (negocio: string, user: string, detalleAutorizacion: string) => {
          push.create(negocio, {
            body: detalleAutorizacion,
            timeout: 8000,
            vibrate: true,
          });
          const notificacion = {} as Notificacion;
          notificacion.acciones = true;
          notificacion.descripcion = detalleAutorizacion;
          notificacion.fecha = new Date();
          notificacion.solicitante = user;
          notificacion.negocio = negocio;
          notificacion.titulo = "Solicitud de Autorización";
          // this.alert=true;
          // this.alertmsg=detalleAutorizacion;
          // this.$store.commit("alert", {
          //   color: "error",
          //   text: "Could not load purchase order info. Status Code: ",
          // });
          this.registrarNotificacion(notificacion);
          console.log("Notificación recibida");
          alert(detalleAutorizacion);
          this.$router.push("/notificaciones");
          // notification
          // window.Notification;
        }
      );
      console.log("Conectado");
      this.setConexion(true);
    }
    this.conectarseNotificaciones({
      negocios: this.negocios,
      conexion: this.ConexionNotificaciones,
    });
    this.cargadeDatos = false;
  },
});
