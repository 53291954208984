















































































import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "App",

  data: () => ({
    drawer: false as boolean,
    mostrarLista: false as boolean,
  }),
  methods: {
    irInicio() {
      this.mostrarLista = false;
      if (this.$route.name != "Inicio") this.$router.push("/");
    },
    irPerfil() {
      this.mostrarLista = false;
      if (this.$route.name != "Perfil") this.$router.push("/perfil");
    },
    cerrarSesion() {
      localStorage.clear();
      this.$router.push("/login");
    },
    irProductos() {
      this.$router.push("Productos");
    },
    irVentas() {
      this.$router.push("Ventas");
    },
    irNotificaciones() {
      this.$router.push("notificaciones");
    }
  },
  computed: {
    ...mapState(["Notificaciones"]),
    validaRutaLogin() {
      return this.$route.path == "/login" ? true : false;
    },
  },
  mounted(){
    Notification.requestPermission();
  }
});
